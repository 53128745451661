import React, {useEffect} from 'react';
import './App.css';
import Register from "./pages/Register";
import PricingPage from "./pages/PricingPage";
import Home2 from "./pages/Home2";
import Login from "./pages/Login";
import {Routes, Route} from "react-router-dom";
import Profile from "./pages/Profile";
import Billing from "./pages/Billing";
import AuthRoutes from "./utils/AuthRoutes";
import VerifyEmail from "./pages/VerifyEmail";
import RegisterSuccess from "./pages/RegisterSuccess";
import TermsAndConditions from "./pages/TermsAndConditions";
import ForgotPasswordEmail from "./pages/ForgotPasswordEmail";
import ResetPassword from "./pages/ResetPassword";
import PaymentSuccess from "./pages/PaymentSuccess";

function App() {
    useEffect(()=>{

    }, [])

    return (
            <div className={"font-inter"}>
                <Routes>
                    <Route element={<AuthRoutes />}>
                        <Route path="/" element={<Home2/>}/>
                        <Route path="/profile" element={<Profile />}/>
                        <Route path="/billing" element={<Billing />}/>
                        <Route path="/pricing" element={<PricingPage />}/>
                    </Route>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register />}/>
                    <Route path="/verify-email" element={<VerifyEmail/>}/>
                    <Route path="/register-success" element={<RegisterSuccess/>}/>
                    <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
                    <Route path="/forgot-password" element={<ForgotPasswordEmail/>}/>
                    <Route path="/reset-password" element={<ResetPassword/>}/>
                    <Route path="/payment-success" element={<PaymentSuccess/>}/>
                </Routes>
            </div>
    );
}

export default App;

