import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {http} from "../lib/http";
import {useNavigate} from "react-router-dom";
import {SiTicktick} from "react-icons/si";


export default function ForgotPasswordEmail() {

    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    const sendForgotPasswordEmail = () => {
        http.post("licenses/send-password-reset-email", {email}).then((res) => {
            if (res.status === 200) {
                localStorage.setItem("resendAttempts", "1");
                setSuccess(true);
            }
        });
    };

    const goBack = () => {
        navigate("/login")
    }

    return (

         success?<main>
             <div className={"flex w-screen justify-center items-center h-screen"}>
                 <div className={"h-2/3 w-1/3 flex justify-center items-center border rounded-2xl flex-col"}>
                     <div className={"flex items-center justify-center bg-battGreen h-20 w-20 rounded-full"}>
                         <SiTicktick size={40} color={"white"}/>
                     </div>
                     <div className={"text-2xl mt-10"}>
                         Password Reset Successful
                     </div>
                     <div className={"mt-5 text-battDarkBlue"}>
                         Please check your email for password reset link.
                     </div>
                 </div>
             </div>
         </main>:<div className={"flex w-screen justify-center items-center h-screen"}>
             <div className={"flex flex-col px-28 py-16 justify-center border rounded-lg"}>
                 <div className={"mt-4 w-80"}>
                     <div>
                         <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Please enter your
                             registered email</label>
                         <div className="mt-2">
                             <input value={email} onChange={(e) => {
                                 setEmail(e.target.value)
                             }} id="email" name="email" type="email" autoComplete="email" required
                                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                         </div>
                     </div>
                 </div>
                 <div className={"flex mt-8 justify-center items-center"}>
                     <div onClick={sendForgotPasswordEmail}
                          className={"bg-battGreen py-1.5 cursor-pointer px-6 rounded-3xl mr-4"}>
                         <div className={"text-sm text-bg-blue"}>Send Reset Password Link</div>
                     </div>
                     <div onClick={goBack}
                          className={"border border-blue-dark cursor-pointer py-1.5 px-6 rounded-3xl"}>
                         <div className={"text-sm text-bg-blue"}>Cancel</div>
                     </div>
                 </div>
             </div>
         </div>)
}