import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import {useEffect, useState} from "react";
import {http} from "../../src/lib/http";

const Profile = () => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [company, setCompany] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        http.get('/users').then((res) => {
            setFirstName(res.data.first_name)
            setLastName(res.data.last_name)
            setCompany(res.data.company)
            setEmail(res.data.email)
        }).catch((e) => {
            alert(e.data.message)
        })
    }, []);


    return (
        <div className="flex ">
            <Sidebar/>
            <div className="relative flex flex-col flex-1">
                <Header />
                <div className={"pl-64 flex flex-col"}>
                    <div className={"px-20"}>
                        <div className="border-b border-gray-900/10 pb-12">
                            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label htmlFor="first-name"
                                           className="block text-sm font-medium leading-6 text-gray-900">First
                                        name</label>
                                    <div className="mt-1">
                                        <input value={firstName} type="text" name="first-name" id="first-name"
                                               autoComplete="given-name" onChange={(e)=>{setFirstName(e.target.value)}}
                                               className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="last-name"
                                           className="block text-sm font-medium leading-6 text-gray-900">Last
                                        name</label>
                                    <div className="mt-1">
                                        <input value={lastName} type="text" name="last-name" id="last-name"
                                               autoComplete="family-name" onChange={(e)=>{setLastName(e.target.value)}}
                                               className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="email"
                                           className="block text-sm font-medium leading-6 text-gray-900">Email
                                        address</label>
                                    <div className="mt-1">
                                        <input value={email} id="email" name="email" type="email"
                                               autoComplete="email" onChange={(e)=>setEmail(e.target.value)}
                                               className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>



                                <div className="sm:col-span-2">
                                    <label htmlFor="postal-code"
                                           className="block text-sm font-medium leading-6 text-gray-900">Company</label>
                                    <div className="mt-1">
                                        <input type="text" name="postal-code" id="postal-code"
                                               autoComplete="postal-code" value={company} onChange={(e)=>{setCompany(e.target.value)}}
                                               className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"mt-4"}>
                            <div className="col-span-full">
                                <label htmlFor="cover-photo"
                                       className="block text-sm font-medium leading-6 text-gray-900">Upload
                                    Image</label>
                                <div
                                    className="mt-1 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className="text-center">
                                        <svg className="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24"
                                             fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                  d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                                                  clip-rule="evenodd"/>
                                        </svg>
                                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                            <label htmlFor="file-upload"
                                                   className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                                                <span>Upload a file</span>
                                                <input id="file-upload" name="file-upload" type="file"
                                                       className="sr-only"/>
                                            </label>
                                            <p className="pl-1">or drag and drop</p>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to
                                            10MB</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={"w-full flex justify-center"}>
                        <div className={"flex mt-8 mb-20 "}>
                            <div onClick={() => {
                            }}
                                 className={"bg-battGreen py-1.5 cursor-pointer px-12 rounded-3xl mr-4"}>
                                <div className={"text-white text-bg-blue"}>Update Profile</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;