import Header from "../components/Header";
import {useNavigate} from "react-router-dom";

const Home2 = () => {

    const workSpaceName = localStorage.getItem("workSpaceName");
    const navigate = useNavigate();

    const goToPricing = ()=>{
        navigate("/pricing");
    }

    const goToBattStudio = ()=>{
        window.open(`https://${workSpaceName}.battstudio.life`);
    }

    return (
        <main>
            <div className={"h-screen w-screen"}>
                <div className={"z-40"}>
                    <Header/>
                </div>
                <div  className={"flex justify-center items-center z-40"}>
                    <div className={"flex flex-col justify-center h-[90vh] gap-8"}>
                        {workSpaceName ? <div className={"text-center px-5"}>
                            <div>Go to your BattStudio at <span onClick={goToBattStudio}
                                                                className={"font-medium cursor-pointer underline"}>{`https://${workSpaceName}.battstudio.life`}</span>
                            </div>
                            <div className={"mt-8"}>You may bookmark this page for direct access in future.</div>
                        </div> : <div className={"text-center px-5"}>
                            You don't have any active plan yet. Please go to <span onClick={goToPricing}
                                                                                   className={"font-medium cursor-pointer underline"}>pricing</span> to
                            explore plans.
                        </div>}
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Home2;