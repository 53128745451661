import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export async function fetchToken(type:string, email:string, password:string){
    const refreshToken = localStorage.getItem("refreshToken");
    const params = new URLSearchParams();
    params.append('client_id', process.env.REACT_APP_BATTKEY_CLIENTID || "");
    if(type==="login"){
        params.append('grant_type', 'password');
        params.append('username', email);
        params.append('password', password);
    }else{
        params.append('grant_type', 'refresh_token');
        params.append('refresh_token', refreshToken || "");
    }


    const url = `${process.env.REACT_APP_BATTKEY_URL}/realms/${process.env.REACT_APP_BATTKEY_REALM}/protocol/openid-connect/token`;
    try {
        const response = await fetch(url, {
            method: "POST",
            body: params,
            headers: {
                "Content-type": "application/x-www-form-urlencoded"
            }
        });
        const responseText = await response.text();
        const data = JSON.parse(responseText);
        if (response.ok) {
            localStorage.setItem("accessToken", data.access_token);
            localStorage.setItem("refreshToken", data.refresh_token);
            document.cookie = `jwt=${data.access_token}; domain=${process.env.REACT_APP_COOKIE_DOMAIN}; path=/; expires=EXPIRATION_DATE;`;
            return true;
        } else {
            return false;
        }
    }catch (e){
        return false;
    }
}