import { configureStore } from '@reduxjs/toolkit';
import loaderReducer from './redux/loadingSlice';
import loginReducer from './redux/loginSlice';
import appLoadingReducer from './redux/appLoadingSlice';

export default configureStore({
    reducer: {
        loader:loaderReducer,
        login:loginReducer,
        appLoader:appLoadingReducer
    },
})