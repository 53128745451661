import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
    name: 'login',
    initialState: {value:false},
    reducers: {
        changeLogin: (state, action) => {
            state.value = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { changeLogin } = loginSlice.actions

export default loginSlice.reducer