import React from "react";
import  Logo from "../assets/logo.svg";
const Footer = () => {

    const goToBattGenie = ()=>{
        window.location.href = "https://battgenie.life/";
    }

    return (
        <footer className="w-full">
            <div className={"flex bg-battDarkBlue h-20 flex items-center justify-between px-8"}>
                <img className={"h-5 cursor-pointer"} onClick={goToBattGenie} src={Logo} alt={"logo"}/>
                <div className={"text-white text-sm"}>
                    4625 Union Bay Pl NE, Seattle, WA - 98105
                </div>
            </div>
        </footer>
    )
}

export default Footer;