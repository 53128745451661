import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import {useEffect, useState} from "react";
import {http} from '../lib/http';

const Billing = () => {

    const [billingData, setBillingData] = useState([]);

    useEffect(() => {
        http.get("/billing").then((res) => {

        })
    }, []);

    return (
        <div className={"w-screen h-screen"}>
            <Sidebar/>
            <Header/>


            <div className={"flex w-fullScreenWithSideNav h-fullScreenWithNav " +
                " relative left-navbarWidth justify-center items-center"}>
                <div>No Billing History</div>
            </div>

        </div>
    )
}

export default Billing;