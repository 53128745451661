import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {http} from "../lib/http";
import {useNavigate} from "react-router-dom";



export default function VerifyEmail() {

    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const navigate = useNavigate();

    useEffect(() => {
        http.post("/licenses/verify-email", {token:token}).then((res)=>{
            navigate("/")
        })
    }, []);

    return (<div className={"flex w-screen justify-center items-center h-screen"}>
        Please wait while the email is being verified....
    </div>)
}