import BattGenieCity from "../assets/BattGenie City.png";
import Logo from "../assets/Brand mark.png";
import Title from "../assets/Word mark.png";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {http} from "../lib/http";
import Footer from "../components/Footer";


export default function Register() {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [company, setCompany] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [termsAccepted, setTermsAccepted] = useState(false);

    const goToTermsAndConditions = () => {
        window.open("terms-and-conditions", "_blank");
    }

    const [error, setError] = useState(
        {
            email: false, password: false, firstName: false, lastName: false, company: false,
            emailMessage: "Please enter a valid email address",
            firstNameMessage: "Please enter a valid first name",
            lastNameMessage: "Please enter a valid last name",
            passwordMessage: "Please enter a password with atleast 8 characters in length, one uppercase, one lowercase, one number and one special character.",
            companyMessage: "Please enter a valid company name",
        }
    );
    const navigate = useNavigate();

    const navigateToSignIn = () => {
        navigate("/login");
    }

    const changeEmail = (email: string) => {
        const err = {...error};
        setEmail(email);
        if (!email) {
            err.email = true;
        } else {
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const res = regex.test(email);
            if (!res) {
                err.email = true;
            } else {
                err.email = false;
            }
        }
        setError(err);
    };

    const changeFirstName = (firstName: string) => {
        const err = {...error};
        setFirstName(firstName);
        if (!firstName) {
            err.firstName = true;
        } else {
            err.firstName = false;
        }
        setError(err);
    }

    const changeLastName = (lastName: string) => {
        const err = {...error};
        setLastName(lastName);
        if (!lastName) {
            err.lastName = true;
        } else {
            err.lastName = false;
        }
        setError(err);
    }

    const changePassword = (password: string) => {
        setPassword(password)
        const err = {...error};
        if (!password) {
            err.password = true;
        } else {
            const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            const res = regex.test(password);
            if (!res) {
                err.password = true;
            } else {
                err.password = false;
            }
        }
        setError(err);
    }

    const changeCompany = (company: string) => {
        setCompany(company)
        const err = {...error};
        if (!company) {
            err.company = true;
        } else {
            err.company = false;
        }
    }

    const handleClick = (e: any) => {
        e.preventDefault();
        if (error.email || error.firstName || error.lastName || error.company || error.password) {
            alert('Please correct the errors first.');
        } else {
            http.post('/licenses/register', {
                "username": email,
                "email": email,
                "company": company,
                first_name: firstName,
                last_name: lastName,
                password: password
            }).then((res) => {
                if (res.status === 201) {
                    navigate("/register-success", {state: {email: email}})
                } else {
                    alert(res?.data?.message)
                }
            }).catch((e) => {
                alert(e?.response?.data?.message)
            })
        }
    }

    return (
        <main>
            <div className={"md:flex h-screen"}>
                <div className={"md:w-1/2 h-full flex flex-col justify-between"}>
                    <div className={"p-4 md:px-20 md:py-8"}>
                        <div className={"flex items-center"}>
                            <img className={"h-10 w-10"} src={Logo} alt={""}/>
                            <img className={"h-5 ml-4 w-auto"} src={Title} alt={""}/>
                        </div>
                        <div>
                            <div className={"text-bg-green mt-4 text-xl font-bold"}>
                                Magical, yet scientific!
                            </div>
                            <div className={"font-bg-blue text-sm mt-4"}>
                                Here’s how we created a super-smart, shockingly efficient way to charge and manage
                                batteries
                            </div>
                            <div className={"font-bg-blue text-sm mt-2 font-bold"}>
                                Welcome, Please create your account
                            </div>
                        </div>
                        <div>
                            <div className="md:pt-2 pb-4">
                                <div className="mt-6 grid grid-cols-1 gap-x-6 md:gap-y-6 gap-y-4 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                        <label htmlFor="first-name"
                                               className="block text-sm font-medium leading-6 text-gray-900">First
                                            name</label>
                                        <div className="md:mt-2 mt-1">
                                            <input placeholder={"Your first name"} value={firstName} onChange={(e) => {
                                                changeFirstName(e.target.value)
                                            }} type="text" name="first-name" id="first-name" autoComplete="given-name"
                                                   className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                            {error.firstName ? <span
                                                className={"text-red-500 text-sm"}>{error.firstNameMessage}</span> : null}
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="last-name"
                                               className="block text-sm font-medium leading-6 text-gray-900">Last
                                            name</label>
                                        <div className="md:mt-2 mt-1">
                                            <input placeholder={"Your last name"} value={lastName} onChange={(e) => {
                                                changeLastName(e.target.value)
                                            }} type="text" name="last-name" id="last-name" autoComplete="family-name"
                                                   className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                            {error.lastName ? <span
                                                className={"text-red-500 text-sm"}>{error.lastNameMessage}</span> : null}
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="email"
                                               className="block text-sm font-medium leading-6 text-gray-900">Email
                                            address</label>
                                        <div className="md:mt-2 mt-1">
                                            <input required={true} placeholder={"Enter your email here.."} value={email}
                                                   onChange={(e) => {
                                                       changeEmail(e.target.value)
                                                   }} id="email" name="email" type="email" autoComplete="email"
                                                   className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                            {error.email ?
                                                <span
                                                    className={"text-red-500 text-sm"}>{error.emailMessage}</span> : null}
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="password"
                                               className="block text-sm font-medium leading-6 text-gray-900">Password</label>
                                        <div className="md:mt-2 mt-1">
                                            <input value={password} onChange={(e) => {
                                                changePassword(e.target.value)
                                            }} placeholder={"Enter a strong password"} id="password" name="password"
                                                   type="password"
                                                   className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                            {error.password ? <span
                                                className={"text-red-500 text-sm"}>{error.passwordMessage}</span> : null}
                                        </div>
                                    </div>

                                    <div className="sm:col-span-4">
                                        <label htmlFor="company"
                                               className="block text-sm font-medium leading-6 text-gray-900">Company</label>
                                        <div className="md:mt-2 mt-1">
                                            <input value={company} onChange={(e) => {
                                                changeCompany(e.target.value)
                                            }} placeholder={"Your company name"} id="company" name="company"
                                                   type="company"
                                                   autoComplete="company"
                                                   className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                            {error.company ? <span
                                                className={"text-red-500 text-sm"}>{error.companyMessage}</span> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={"mt-2"}>
                            <div className={"flex items-center mb-4"}>
                                <input checked={termsAccepted} onChange={(e) => {
                                    setTermsAccepted(e.target.checked)
                                }} type={"checkbox"}/>
                                <div className={"ml-2 text-sm"}>By registering, you agree to BattGenie's <span
                                    onClick={goToTermsAndConditions}
                                    className={"underline text-battDarkBlue cursor-pointer"}>terms and conditions</span>.
                                </div>
                            </div>
                            <div onClick={handleClick}
                                 className={"bg-battGreen inline-block py-1.5 cursor-pointer px-20 rounded-3xl mr-4"}>
                                <div className={"text-sm text-battDarkBlue"}>Register</div>
                            </div>
                            <div>
                                <div onClick={navigateToSignIn}
                                     className={"text-sm cursor-pointer text-battDarkBlue underline mt-4 "}> or Login to
                                    your account
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
                <div className={"w-full md:w-1/2 md:h-full md:fixed md:right-0"}>
                    <img className={"md:h-screen w-full bg-blue-light"} src={BattGenieCity} alt={""}/>
                </div>
            </div>
        </main>
    )
}
