import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {http} from "../lib/http";
import {SiTicktick} from "react-icons/si";


export default function RegisterSuccess() {

    const {state} = useLocation();
    const [email, setEmail] = useState("");

    useEffect(() => {
        let {email} = state;
        if (!email) {
            email = localStorage.getItem("userEmail");
        } else {
            localStorage.setItem("userEmail", email);
        }
        setEmail(email)
    }, [state]);

    const resendEmail = () => {
        let attempts = localStorage.getItem("resendAttempts");
        if(attempts){
            if(parseInt(attempts)>=1) {
                alert("Email already sent");
                return;
            }
        }
        http.post("licenses/resend-account-email", {email}).then((res) => {
            if (res.status === 200) {
                localStorage.setItem("resendAttempts", "1");
                alert("Email sent successfully");
            }
        })
    }

    return (
        <main>
            <div className={"flex w-screen justify-center items-center h-screen"}>
                <div className={"h-2/3 w-1/3 flex justify-center items-center border rounded-2xl flex-col"}>
                    <div className={"flex items-center justify-center bg-battGreen h-20 w-20 rounded-full"}>
                        <SiTicktick size={40} color={"white"}/>
                    </div>
                    <div className={"text-2xl mt-10"}>
                        Registration Successful
                    </div>
                    <div className={"mt-5 text-battDarkBlue"}>
                        Please check your email for account verification.
                    </div>
                    <div className={"mt-20"}>
                        Didn't receive email?
                    </div>
                    <div onClick={resendEmail} className={"bg-battDarkBlue py-3 px-10 mt-5 rounded-3xl cursor-pointer"}>
                        <div className={"text-white"}>
                            Resend Email
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
