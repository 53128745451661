import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {http} from "../lib/http";
import {useNavigate} from "react-router-dom";


export default function ResetPassword() {

    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const navigate = useNavigate();

    const resetPassword = ()=>{
        if(!password){
            alert("Please enter a valid password.")
            return;
        }
        if(password !== confirmPassword){
            alert("Passwords don't match");
            return;
        }else{
            http.post("licenses/reset-password", {token:token, password:password}).then((res) => {
                if (res.status === 200) {
                    alert("Password reset successful.");
                    navigate("/login");
                }
            });
        }
    }

    const goBack = ()=>{
        navigate("/login")
    }

    return (<div className={"flex w-screen justify-center items-center h-screen"}>
        <div className={"flex flex-col px-28 py-16 justify-center border rounded-lg"}>
            <div className="mt-2 w-80">
                <div className="flex items-center justify-between mb-2">
                    <label htmlFor="password"
                           className="block text-sm font-medium leading-6 text-gray-900">Please enter your new password</label>
                </div>
                <input value={password} onChange={(e) => {
                    setPassword(e.target.value)
                }} id="password" name="password" type="password" autoComplete="current-password"
                       required
                       className="block w-full rounded-md border-0 p-1.5  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
            </div>
            <div className="mt-4 w-80">
                <div className="flex items-center justify-between mb-2">
                    <label htmlFor="password"
                           className="block text-sm font-medium leading-6 text-gray-900">Please enter your new password</label>
                </div>
                <input value={confirmPassword} onChange={(e) => {
                    setConfirmPassword(e.target.value)
                }} id="password" name="password" type="password" autoComplete="current-password"
                       required
                       className="block w-full rounded-md border-0 p-1.5  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
            </div>
            <div className={"flex mt-8 justify-center items-center"}>
                <div onClick={resetPassword}
                     className={"bg-battGreen py-1.5 cursor-pointer px-6 rounded-3xl mr-4"}>
                    <div className={"text-sm text-bg-blue"}>Reset Password</div>
                </div>
                <div onClick={goBack}
                     className={"border border-blue-dark cursor-pointer py-1.5 px-6 rounded-3xl"}>
                    <div className={"text-sm text-bg-blue"}>Cancel</div>
                </div>
            </div>
        </div>
    </div>)
}