import { ColorRing } from 'react-loader-spinner'

const Loader = ()=> {
    return (
        <div className={"h-screen w-screen flex justify-center items-center"}>
            <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['#01DEBD', '#01DEBD', '#01DEBD','#01DEBD','#01DEBD']}
            />
        </div>
    )
}

export default Loader;
