// @ts-nocheck
import React, {useState, useRef} from 'react';
import {NavLink, useLocation} from 'react-router-dom';

const HomeSvg = (props)=>{
    return (
        <svg color={props.color} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             className="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"/>
        </svg>

    )
}

const BillingSvg = (props) => {
    return (<svg color={props.color} className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <path
            className={`fill-current ${props.pathname==="/"?'bg-battGreen':'bg-white'}`}
            d="M1 3h22v20H1z"/>
        <path
            className={`fill-current ${props.pathname.includes('calendar') ? 'text-indigo-300' : 'text-slate-400'}`}
            d="M21 3h2v4H1V3h2V1h4v2h10V1h4v2Z"
        />
    </svg>)
}

const ProfileSvg = (props)=>{
    return (<svg color={props.color} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round"
              d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
    </svg>)
}


const NavItem = (props) => {
    return (
        <div>
            <li className={`px-3 py-4 rounded-sm mb-0.5 last:mb-0 ${props.pathname.includes('campaigns') && 'bg-slate-900'}`}>
                <NavLink
                    end
                    to={props.data.link}
                    className={`block text-slate-200 truncate transition duration-150 ${
                        props.pathname.includes('campaigns') ? 'hover:text-slate-200' : 'hover:text-white'
                    }`}
                >
                <div className="flex items-center">
                        <props.data.svg color={props.data.color} pathname={props.pathname} />
                        <span style={{color:props.data.color}}
                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      {props.data.name}
                    </span>
                    </div>
                </NavLink>
            </li>
        </div>
    )
}

// @ts-ignore
function Sidebar() {
    const location = useLocation();
    const {pathname} = location;
    const navData = [
        {name:"Home", link:"/", svg:HomeSvg, color:pathname==="/"?'#01DEBD':"white"},
        {name:"Profile", link:"/profile", svg:ProfileSvg, color:pathname==="/profile"?'#01DEBD':"white"},
        {name:"Billing", link:"/billing", svg:BillingSvg, color:pathname==="/billing"?'#01DEBD':"white"},
    ]

    const sidebar = useRef(null);

    const [sidebarExpanded, setSidebarExpanded] = useState(true);

    return (
        <div className={"fixed z-50"}>
            {/* Sidebar backdrop (mobile only) */}

            {/* Sidebar */}
            <div
                id="sidebar"
                ref={sidebar}
                className={`flex flex-col z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-navbarWidth lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-battDarkBlue transition-all duration-200 ease-in-out`}
            >
                {/* Sidebar header */}
                <div className="flex  justify-between mb-5 border-b p-3">
                    {/* Logo */}
                    <NavLink end to="/" className="block">
                        <img className={"w-4/5"} src={require("../images/batt-logo-dark.png")} alt={""}/>
                    </NavLink>
                </div>

                {/* Links */}
                <div className="space-y-8">
                    {/* Pages group */}
                    <div>
                        <ul className="mt-3">
                            {navData.map((item)=>{
                                return <NavItem pathname={pathname} data={item}/>
                            })}
                        </ul>
                    </div>
                </div>
                </div>
                {/* Expand / collapse button */}
                <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
                    <div className="px-3 py-2">
                        <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
                            <span className="sr-only">Expand / collapse sidebar</span>
                            <svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                                <path className="text-slate-400"
                                      d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"/>
                                <path className="text-slate-600" d="M3 23H1V1h2z"/>
                            </svg>
                        </button>
                    </div>
                </div>
        </div>
    );
}

export default Sidebar;
