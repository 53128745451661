// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.faq-answer-hidden{
  height: 0;
  visibility: hidden;
  opacity: 0;
  padding: 0;
  transition: visibility 0.5s ease, opacity 0.5s ease, height 1s ease, padding 0.7s ease;
}

.faq-answer-visible{
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  height: 150px;
  opacity: 1;
  visibility: visible;
  transition: visibility 1s ease, opacity 1s ease, height 0.8s ease, padding 1s ease;
}

.faq-arrow-hidden{
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 1s ease;
}

.faq-arrow-visible{
  visibility: visible;
  opacity: 1;
  transition: all 1s ease;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,kBAAkB;EAClB,UAAU;EACV,UAAU;EACV,sFAAsF;AACxF;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,UAAU;EACV,mBAAmB;EACnB,kFAAkF;AACpF;;AAEA;EACE,SAAS;EACT,kBAAkB;EAClB,UAAU;EACV,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,UAAU;EACV,uBAAuB;AACzB;;;AAGA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n.faq-answer-hidden{\n  height: 0;\n  visibility: hidden;\n  opacity: 0;\n  padding: 0;\n  transition: visibility 0.5s ease, opacity 0.5s ease, height 1s ease, padding 0.7s ease;\n}\n\n.faq-answer-visible{\n  padding-top: 1.5rem;\n  padding-bottom: 0.5rem;\n  height: 150px;\n  opacity: 1;\n  visibility: visible;\n  transition: visibility 1s ease, opacity 1s ease, height 0.8s ease, padding 1s ease;\n}\n\n.faq-arrow-hidden{\n  height: 0;\n  visibility: hidden;\n  opacity: 0;\n  transition: all 1s ease;\n}\n\n.faq-arrow-visible{\n  visibility: visible;\n  opacity: 1;\n  transition: all 1s ease;\n}\n\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
