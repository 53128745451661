import Header from "../components/Header";
import Pricing from "../components/Pricing";

export default function PricingPage() {
    return (<>
            <Header/>
            <Pricing />
        </>
    )
}
