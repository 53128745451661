import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {http} from "../lib/http";
import {SiTicktick} from "react-icons/si";
import Header from "../components/Header";


export default function PaymentSuccess() {

    useEffect(() => {

    }, []);


    return (
        <main className={"h-screen w-screen overflow-hidden"}>
            <Header />
            <div className={"flex h-full w-full justify-center mt-20"}>
                <div className={"h-2/3 w-1/3 flex justify-center items-center border rounded-2xl flex-col px-8"}>
                    <div className={"flex items-center justify-center bg-battGreen h-20 w-20 rounded-full"}>
                        <SiTicktick size={40} color={"white"}/>
                    </div>
                    <div className={"text-2xl mt-10"}>
                        Payment Successful
                    </div>
                    <div className={"mt-8 text-battDarkBlue text-justify leading-8"}>
                        Your BattStudio instance is being freshly baked and should be ready in under 5 minutes. You will receive a login link with an email or check back here in 5 mins
                    </div>
                </div>
            </div>
        </main>
    )
}
