import React from "react";
import Header from "../components/Header";

const TermsAndConditions = () => {

    const date = new Date();
    const formattedDate = new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    }).format(date);

    return (<div>
        <Header/>
        <div className={"h-40 flex flex-col justify-center items-center"}>
            <div className={"text-battDarkBlue text-4xl text-center"}>
                BattStudio Self-Serve Terms and Conditions
            </div>
        </div>
        <div className={"container px-52 pt-5 pb-20 leading-7"}>
            <div className={"font-bold text-xl pb-4"}>1. Introduction</div>
            <div className={"text-justify pb-8"}>Welcome to BattStudio, a battery data management Software-as-a-Service
                (SaaS) solution. These Terms and Conditions govern your use of our services, including our website and
                cloud-based platform. By accessing or using BattStudio, you agree to be bound by these terms.
            </div>
            <div className={"font-bold text-xl pb-4"}>2. Service Description</div>
            <div className={"text-justify pb-8"}>
                BattStudio provides a cloud-hosted battery data management solution. Our service utilizes proprietary
                code and modified open-source packages to deliver a comprehensive data management experience.
            </div>
            <div className={"font-bold text-xl pb-4"}>3. Data Privacy and Security</div>
            <div className={"text-justify pb-8"}>
                <div className={"pb-2"}><span className={"font-bold"}>3.1.</span> We prioritize the security and privacy
                    of your data. All
                    customer data is fully encrypted and
                    stored in dedicated instances within our public cloud infrastructure.
                </div>

                <div className={"pb-2"}><span className={"font-bold"}>3.2.</span> While we implement robust security
                    measures, you
                    acknowledge that no method of electronic storage
                    is 100% secure. You agree to use the service at your own risk.
                </div>

                <div className={"pb-2"}><span className={"font-bold"}>3.3.</span> We do not access, use, or share your
                    data except as
                    necessary to provide and maintain the service,
                    or as required by law.
                </div>
            </div>

            <div className={"font-bold text-xl pb-4"}>4. User Responsibilities</div>
            <div className={"text-justify pb-8"}>
                <div className={"pb-2"}><span className={"font-bold"}>4.1.</span> You are responsible for
                    maintaining the confidentiality of your account credentials.
                </div>
                <div className={"pb-2"}><span className={"font-bold"}>4.2. </span>
                     You agree to use the service in compliance with all applicable laws and regulations.
                </div>
                <div className={"pb-2"}><span className={"font-bold"}>4.3. </span>
                    You shall not attempt to reverse engineer, decompile, or otherwise attempt to derive the source
                    code of our proprietary software.
                </div>
            </div>
            <div className={"font-bold text-xl pb-4"}>5. Intellectual Property</div>
            <div className={"text-justify pb-8"}>
                <div className={"pb-2"}><span className={"font-bold"}>5.1. </span>BattStudio and its original content, features, and functionality are owned by us and are protected
                by international copyright, trademark, patent, trade secret, and other intellectual property laws.
                </div>
                <div className={"pb-2"}><span className={"font-bold"}>5.2. </span>
                Our service uses open-source software components. Respective license terms for these components are
                    available upon request.</div>
            </div>
            <div className={"font-bold text-xl pb-4"}>6. Limitation of Liability</div>
            <div className={"text-justify pb-8"}>
                <div className={"pb-2"}><span className={"font-bold"}>6.1. </span>
                BattStudio shall not be liable for any indirect, incidental, special, consequential or punitive
                damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses.
                </div>
                <div className={"pb-2"}><span className={"font-bold"}>6.2. </span>
                In no event will our liability exceed the amount you paid for the service in the last 12 months.
                </div>
            </div>
            <div className={"font-bold text-xl pb-4"}>7. Modifications to Service</div>
            <div className={"text-justify pb-8"}>
                We reserve the right to withdraw or amend our service, and any service or material we provide, in our
                sole discretion without notice. We will not be liable if for any reason all or any part of the service
                is unavailable at any time or for any period.
            </div>
            <div className={"font-bold text-xl pb-4"}> 8. Governing Law</div>
            <div className={"text-justify pb-8"}>
                These Terms shall be governed and construed in accordance with the laws of Seattle, WA, without
                regard to its conflict of law provisions.
            </div>
            <div className={"font-bold text-xl pb-4"}>9. Changes to Terms</div>
            <div className={"text-justify pb-8"}>
                We reserve the right to modify or replace these Terms at any time. It is your responsibility to check
                these Terms periodically for changes.
            </div>
            <div className={"font-bold text-xl pb-4"}>10. Contact Us</div>
            <div className={"text-justify pb-8"}>
                If you have any questions about these Terms, please contact us at <span className={"font-medium"}>info@battgenie.life</span>.
            </div>
            <div className={"pb-8 font-medium"}>
                By using BattStudio, you acknowledge that you have read and understood these Terms and Conditions and
                agree
                to be bound by them.
            </div>
            <div>
                Last updated: <span className={"font-medium"}>{formattedDate}</span>
            </div>


        </div>
    </div>)
}

export default TermsAndConditions;