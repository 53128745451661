import BattGenieCity from "../assets/BattGenie City.png";
import Logo from "../assets/Brand mark.png";
import Title from "../assets/Word mark.png";
import Checkbox from "../assets/checkbox.png";
import {useNavigate} from "react-router-dom";
import {http} from "../lib/http";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../components/Loader";
import {changeLoader} from "../redux/loadingSlice";
import {changeLogin} from "../redux/loginSlice";
import Footer from "../components/Footer";
import {fetchToken} from "../lib/utils";


export default function Login() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const dispatch = useDispatch();
    const loading = useSelector((state: any) => state.loader);
    const fetchLicense = (email: String) => {
        http.get("/users/get-user-license?email=" + email).then((res) => {
            dispatch(changeLoader(false));
            if (res.status === 200) {
                if (res.data.user) {
                    localStorage.setItem("username", res.data?.user?.first_name);
                    localStorage.setItem("workSpaceName", res.data?.user?.workspace_name);
                }
                if (res.data.license) {
                    localStorage.setItem("planId", res.data?.license?.planid);
                    localStorage.setItem("planExpiry", res.data?.license?.expirydate);
                    navigate("/")
                } else {
                    navigate("/pricing")
                }
            }
        }).catch(() => {
            dispatch(changeLoader(false));
            navigate("/pricing");

        })
    }


    useEffect(() => {
        fetchToken("refresh", "", "").then((response)=>{
            if (response) {
                navigate("/");
            }
        });
    }, []);

    const navigate = useNavigate();
    const handleClick = async () => {
        try {
            dispatch(changeLoader(true));
            const response = await fetchToken("login", email, password);
            if (response) {
                localStorage.setItem("email", email);
                dispatch(changeLogin(true));
                fetchLicense(email);
            } else {
                alert("Email or password incorrect. Please try again.")
                dispatch(changeLoader(false));
            }
        } catch (e) {
            dispatch(changeLoader(false));
            alert("Some error occurred. Please contact admin.")
        }
    }

    const navigateToRegister = () => {
        navigate("/register");
    }

    const forgotPassword = () => {
        navigate("/forgot-password")
    }

    return (
        loading.value ? <Loader/> : <main>
            <div className={"md:flex md:h-screen"}>
                <div className={"w-full md:w-2/5 h-screen flex flex-col justify-between"}>
                    <div className={"p-4 md:p-12"}>
                        <div className={"flex items-center"}>
                            <img className={"h-10 w-10"} src={Logo} alt={""}/>
                            <img className={"h-5 ml-4 w-auto"} src={Title} alt={""}/>
                        </div>
                        <div>
                            <div className={"text-bg-green mt-8 md:mt-12 text-xl font-bold"}>
                                Magical, yet scientific!
                            </div>
                            <div className={"font-bg-blue text-sm mt-4"}>
                                Here’s how we created a super-smart, shockingly efficient way to charge and manage
                                batteries
                            </div>
                            <div className={"font-bg-blue text-sm mt-4 md:mt-12"}>
                                Welcome, Please login to your account
                            </div>
                        </div>
                        <div className={"mt-4"}>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email
                                    address</label>
                                <div className="mt-2">
                                    <input value={email} onChange={(e) => {
                                        setEmail(e.target.value)
                                    }} id="email" name="email" type="email" autoComplete="email" required
                                           className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                            </div>
                        </div>
                        <div className={"mt-4"}>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password"
                                       className="block text-sm font-medium leading-6 text-gray-900">Password</label>
                            </div>
                            <div className="mt-2">
                                <input value={password} onChange={(e) => {
                                    setPassword(e.target.value)
                                }} id="password" name="password" type="password" autoComplete="current-password"
                                       required
                                       className="block w-full rounded-md border-0 p-1.5  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                            </div>
                        </div>
                        <div className={"md:flex justify-between items-center mt-4"}>
                            <div className={"flex items-center"}>

                            </div>
                            <div onClick={forgotPassword} className={"pt-4 md:pt-0 text-bg-gray cursor-pointer"}>
                                Forgot Password?
                            </div>
                        </div>
                        <div className={"flex mt-8"}>
                            <div onClick={handleClick}
                                 className={"bg-battGreen py-1.5 cursor-pointer px-6 rounded-3xl mr-4"}>
                                <div className={"text-sm text-bg-blue"}>Login</div>
                            </div>
                            <div onClick={navigateToRegister}
                                 className={"border border-blue-dark cursor-pointer py-1.5 px-6 rounded-3xl"}>
                                <div className={"text-sm text-bg-blue"}>Sign Up</div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>

                <div className={"w-full md:w-3/5 h-full"}>
                    <img className={"md:h-screen w-full"} src={BattGenieCity} alt={""}/>
                </div>
            </div>
        </main>
    )
}
