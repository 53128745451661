import { createSlice } from '@reduxjs/toolkit'

export const loadingSlice = createSlice({
    name: 'loader',
    initialState: {value:false},
    reducers: {
        changeLoader: (state, action) => {
            state.value = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { changeLoader } = loadingSlice.actions

export default loadingSlice.reducer