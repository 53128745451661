import { createSlice } from '@reduxjs/toolkit'

export const appLoadingSlice = createSlice({
    name: 'app-loader',
    initialState: {value:true},
    reducers: {
        changeState: (state, action) => {
            state.value = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { changeState } = appLoadingSlice.actions

export default appLoadingSlice.reducer