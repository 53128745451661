import {Outlet, useNavigate} from "react-router-dom";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeState} from "../redux/appLoadingSlice";
import {changeLogin} from "../redux/loginSlice";
import Loader from "../components/Loader";
import {fetchToken} from "../lib/utils";



const AuthRoutes = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const appLoader = useSelector((state:any) => state.appLoader);
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
        dispatch(changeState(false));
        navigate("/login");
    }else {
        // dispatch(changeState(true));
         fetchToken("refresh", "", "").then((response)=>{
             dispatch(changeState(false));
             if(response){
                 dispatch(changeLogin(true));
             }else{
                 navigate("/login");
             }
         });
    }

    return (
        appLoader.value?<Loader/>:<Outlet/>
    )

}

export default AuthRoutes;