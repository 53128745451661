import Logo from "../assets/Brand mark.png";
import LogoA from "../assets/Word mark.png";
import Avatar from "../assets/3d_avatar_13.png";
import BattGenieLogo from "../assets/BattGenieLogo.svg";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {changeLoader} from "../redux/loadingSlice";
import {changeLogin} from "../redux/loginSlice";
import { IoDocumentTextOutline } from "react-icons/io5";


export default function Header() {

    const [showPopUp, setShowPopUp] = useState(false);
    const navigate = useNavigate();
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [userName, setUserName] = useState("");

    const dispatch = useDispatch();

    const goToDocs = () => {
        window.open(process.env.REACT_APP_BATTSTUDIO_DOCUMENTATION_URL)
    }

    const goToProfile = () => {
        navigate("/profile");
    }

    const goToHome = () => {
        navigate("/");
    }

    const goToBattGenie = () => {
        window.open("https://battgenie.life/");
    }



    useEffect(() => {
        if(localStorage.getItem("username")){
            setUserName(localStorage.getItem("username")||"");
        }else{
            setUserName(localStorage.getItem("email")||"");
        }
    }, []);


    const logout = async () => {
        try {
            dispatch(changeLoader(true));
            const refreshToken = localStorage.getItem("refreshToken") || "";
            const params = new URLSearchParams();
            params.append('client_id', process.env.REACT_APP_BATTKEY_CLIENTID || "");
            params.append('refresh_token', refreshToken);

            const url = `${process.env.REACT_APP_BATTKEY_URL}/realms/${process.env.REACT_APP_BATTKEY_REALM}/protocol/openid-connect/logout`;
            const response = await fetch(url, {
                method: "POST",
                body: params,
                headers: {
                    "Content-type": "application/x-www-form-urlencoded"
                }
            })
            const responseText = await response.text();
            dispatch(changeLogin(false));
            if (response.ok) {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("planExpiry");
                localStorage.removeItem("workSpaceName");
                localStorage.removeItem("username");
                localStorage.removeItem("email");
                localStorage.removeItem("planId");
                navigate("/login");
            } else {
                console.error('Login request failed:', response.status);
                console.error('Response Text:', responseText);
            }
            dispatch(changeLoader(false));
        } catch (error) {
            dispatch(changeLoader(false));
            console.log(error)
        }
    };

    const toggleDropdown = () => {
        setDropDownOpen(!dropDownOpen);
    }

    const closePopUp = () => {
        setShowPopUp(false);
    }

    const openPopUp = () => {
        setShowPopUp(true);
    }

    const login = useSelector((state: any) => state.login);

    const divRef = useRef(null);

    const handleClickOutside = (event: any) => {

        // @ts-ignore
        if (divRef.current && !divRef.current.contains(event.target)) {
            setDropDownOpen(false);
        }
    };

    useEffect(() => {
        // Add event listener to document
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const Popup = () => {
        return (
            <div className={"w-screen h-screen bg-black/50 fixed top-0 left-0 z-30 flex items-center justify-center"}>
                <div className={"bg-white rounded-2xl flex items-center justify-center flex-col px-16 py-12"}>
                    <div className={"text-xl font-bold"}>
                        Are you sure you want to logout?
                    </div>
                    <div className={"flex items-center justify-center mt-12"}>
                        <div onClick={logout} className={"px-10 py-2 bg-battGreen rounded-full cursor-pointer"}>
                            Yes
                        </div>
                        <div onClick={closePopUp} className={"px-10 py-2 rounded-full ml-6 border cursor-pointer"}>
                            No
                        </div>
                    </div>
                </div>
            </div>)
    }

    return (
        <div>
            {showPopUp ? <Popup/> : null}
            <div className={"relative md:h-14 bg-battLightBlue py-2 md:px-8 px-2 md:flex md:justify-between items-center"}>
                <div className={"flex justify-center items-center"}>
                    <img className={"h-6 w-6 cursor-pointer"} onClick={goToHome} src={Logo} alt={'Battgenie logo'}/>
                    <img className={"h-4 pl-2 cursor-pointer"}  onClick={goToHome} src={LogoA} alt={'Battgenie Title'}/>
                </div>
                <div className={"flex  mt-3 md:mt-0"}>
                    <div className={"flex items-center w-full justify-between md:justify-end relative"}>
                        {login.value ? <div className={"flex"}>
                            <div onClick={goToDocs} className={"flex items-center mr-8 cursor-pointer"}>
                                <IoDocumentTextOutline size={22} color={"#222961"}/>
                                <div className={"pl-1"}>Documentation</div>
                            </div>
                        </div> : null}
                        <div className={"flex items-center"}>
                            <div className={"mr-4"}>Hi, {userName}</div>
                            <img onClick={toggleDropdown} className={"h-8 w-auto cursor-pointer"} src={Avatar}
                                 alt={""}/>
                        </div>
                        {dropDownOpen ? <div ref={divRef} className={"absolute bg-white top-10 right-0 rounded z-50"}>
                            <div className={"px-2 border-b-2 py-2"}>
                                {localStorage.getItem("email")}
                            </div>
                            <div onClick={goToProfile} className={"border-b-2 px-4 py-2 cursor-pointer"}>
                                Profile
                            </div>
                            <div onClick={openPopUp} className={"border-b-2 px-4 py-2 cursor-pointer"}>
                                Logout
                            </div>
                        </div> : null}
                    </div>
                </div>

            </div>
        </div>
    )
}

