import React, {useEffect, useState} from "react";
import {loadStripe} from '@stripe/stripe-js';
import {http} from "../../src/lib/http";
import {IoIosArrowUp} from "react-icons/io";
import {IoIosArrowDown} from "react-icons/io";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Loader from "./Loader";
import {changeLoader} from "../redux/loadingSlice";

const enterpriseEmailBody = `I hope this message finds you well. My name is [Your Name], and I am reaching out on behalf of [Your Company]. We are very interested in your BattStudio platform and would like to inquire about your enterprise tier with custom options.

Specifically, we are looking for more information and pricing for the following services:

BattETL (Batch Data Ingestion)
BattDB (Time Series Database) - Customizable
Data Processing Volume - Customizable
Number of Users - Custom (RBAC, SSO, etc.)
Custom Compute Limit
BattMon (Instantaneous Alerting and Data Visualization)
BattETL-RT (Real-Time Data Ingestion)
Real-Time Data Ingestion
Advanced Analytics
Dedicated Support
Customized Options
We would like to schedule a meeting to discuss our specific requirements and explore how BattStudio can support our needs. Could you please provide us with some available time slots for a meeting?

Thank you, and we look forward to your response.

Best regards,

[Your Name]
[Your Position]
[Your Company]
[Your Contact Information]
`;

const SVG = () => {
    return (<div className={"mr-1"}>
        <svg className="flex-shrink-0 w-5 h-5 text-battGreen dark:text-battGreen" fill="currentColor"
             viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
        </svg>
    </div>)
}

const CrossSVG = () => {
    return (
        <div className={"mr-1"}>
            <svg className="flex-shrink-0 w-3.5 h-3.5 text-red-500 dark:text-red-400" fill="currentColor"
                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M3.707 3.293a1 1 0 011.414 0L10 8.586l5.293-5.293a1 1 0 111.414 1.414L11.414 10l5.293 5.293a1 1 0 01-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 01-1.414-1.414L8.586 10 3.293 4.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"></path>
            </svg>
        </div>)
}

const Pricing = () => {

    const [validPlan, setValidPlan]= useState(false);
    const [existingPlanId, setExistingPlanId]= useState("");

    useEffect(() => {
        const existingPlan = localStorage.getItem("planId") || "";
        setExistingPlanId(existingPlan);
        const existingPlanExpiry = localStorage.getItem("planExpiry");
        if (existingPlan) {
            if (existingPlanExpiry) {
                const existingPlanDate = new Date(existingPlanExpiry);
                if (existingPlanDate > new Date()) {
                    setValidPlan(true)
                }
            }
        }
    }, []);

    const [faqData, setFaqData] = useState([{
        id: 1,
        question: "What is BattStudio?",
        answer: "BattStudio is a comprehensive software suite designed for battery modeling, data management, BMS algorithms, and research and development. It offers a unified interface that simplifies working with battery data and simulations, making it easier for developers and researchers to streamline their workflow.",
        selected: false
    },
        {
            id: 2,
            question: "Who can benefit from using BattStudio?",
            answer: "BattStudio is ideal for developers, researchers, and professionals involved in battery technology, including those working on battery modeling, BMS development, and data analysis.",
            selected: false
        }, {
            id: 3,
            question: "Is my data secure on your platform?",
            answer: "Yes, we prioritize data security by implementing robust encryption protocols and secure instance management for each user.",
            selected: false
        }, {
            id: 4,
            question: "What are the key features of BattStudio?",
            answer: "BattDaaS (Battery Data as a Service) - a set of data services integrated within BattStudio that enables efficient data management and analysis. BattSaaS (Battery Simulation as a Service) - a simulation service within BattStudio that allows you to design and test battery models virtually. ",
            selected: false
        }, {
            id: 5,
            question: "Can I access my data in real-time?",
            answer: "Yes, our platform allows real-time data access and analysis for better decision-making and monitoring. The real-time capability of data ingestion and visualization is part of the 'Advanced' tier.",
            selected: false
        },
        {
            id: 7,
            question: "How do you ensure data accuracy during the ETL process?",
            answer: "We use the open-source Python package BattETL (https://github.com/BattGenie/battetl) for the data extraction, transformation and loading. This package includes several unit tests for testing individual functions, and has been battle-tested by being in production usage in several of our customer deployments. Additional custom tests can be added to the data-pipelines to watch for user-defined anomalies and alert conditions.",
            selected: false
        }, {
            id: 8,
            question: "Can I customize the data reports and dashboards?",
            answer: "Yes, our platform offers customizable reports and dashboards to meet your specific analysis needs. Currently, the custom dashboards are part of the 'Enterprise' tier. However, new dashboards are continously added to BattViz and BattMon as we receive requests and continuously upgrade the platform.",
            selected: false
        }, {
            id: 9,
            question: "How scalable is your platform for multiple battery testers?",
            answer: "Our platform is highly scalable and can handle multiple battery testers, providing seamless data integration and analysis.",
            selected: false
        }, {
            id: 10,
            question: "Do you offer any data storage solutions?",
            answer: "Yes, we provide secure and scalable data storage solutions as part of our service. The data is encrypted and stored locally on the instance with continuous and secure backup and restore capability. We provide additional data storage options as needed.",
            selected: false
        }, {
            id: 11,
            question: "Is there a trial period available?",
            answer: "Yes, we offer a trial period for new users to explore our platform and its features. Sign up to start your free trial.",
            selected: false
        }, {
            id: 12,
            question: "How do I get support if I encounter issues?",
            answer: "You can reach out to our support team via email, live chat, or phone. We also have a comprehensive knowledge base for self-help.",
            selected: false
        }, {
            id: 13,
            question: "Can I integrate your platform with other software tools?",
            answer: "Yes, our platform supports integrations with various third-party tools and software through APIs.",
            selected: false
        }, {
            id: 14,
            question: "What are the system requirements for using your platform?",
            answer: "Our platform is web-based and can be accessed via modern web browsers. No additional software is required.",
            selected: false
        }, {
            id: 15,
            question: "How often is the data updated on your platform?",
            answer: "Data is updated in real-time as it is collected from your battery testers.",
            selected: false
        }, {
            id: 16,
            question: "Do you provide training or onboarding assistance?",
            answer: "Yes, we offer training and onboarding sessions to help you get started with our platform efficiently.",
            selected: false
        }, {
            id: 17,
            question: "What measures do you take to ensure platform uptime and reliability?",
            answer: "We use high-availability infrastructure and regular maintenance practices to ensure our platform is reliable and always accessible.",
            selected: false
        }, {
            id: 18,
            question: "Can I export my data from your platform?",
            answer: "Yes, you can export your data in various formats for offline analysis or backup purposes.",
            selected: false
        }, {
            id: 19,
            question: "Is there a limit to the number of users I can have on my BattStudio account?",
            answer: "Currently, the hosted self-seve BattStudio offering (https://battstudio.life) supports only one user at a time. The Enterprise and managed deployments support mutliple users, SSO, RBAC etc. features that enable collaboration and easy use for unlimited users.",
            selected: false
        }, {
            id: 20,
            question: "How do I handle password resets and account recovery in BattStudio?",
            answer: "Users can reset their passwords via the login page. Admins can also send password reset links and assist with account recovery through the user management section.",
            selected: false
        }, {
            id: 21,
            question: "What types of battery models can BattStudio handle?",
            answer: "BattStudio supports a wide range of battery models, including lithium-ion, lead-acid, nickel-metal hydride, and more, with tools for custom model development. Currently, BattStudio self-serve at https://battstudio.life only provides access to Battery Data as a Service (BattDaaS) offering and does not include battery models. The 'Enterprise' and custom managed deployments offer our full range of battery models starting from equivalent circuit-based models (ECM) to physics-based models including single-particle model, tank model, psuedo-2-dimensional (P2D) model as well as AI-enriched and custom models for new chemstries and battery designs. ",
            selected: false
        }, {
            id: 22,
            question: "What should I do if I encounter a technical issue with BattStudio?",
            answer: "If you encounter any technical issues, please contact our support team immediately. We are committed to resolving any problems as quickly as possible.",
            selected: false
        }]);

    const selectFAQ = (id: number) => {
        const data = [...faqData];
        data.forEach((item) => {
            if (item.id === id) {
                item.selected = !item.selected;
            }
        })
        setFaqData(data);
    }

    const [showPopUp, setShowPopUp] = useState(false);

    const closePopUp = () => {
        setShowPopUp(false);
    }

    const openPopUp = () => {
        setShowPopUp(true);
    }

    const loading = useSelector((state: any) => state.loader);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [pricingData, setPricingData] = useState([]);
    const [paymentId, setPaymentId] = useState("");

    const Popup = () => {
        return (
            <div className={"w-screen h-screen bg-black/50 fixed top-0 left-0 z-30 flex items-center justify-center"}>
                <div className={"bg-white rounded-2xl flex items-center justify-center flex-col px-16 py-12"}>
                    <div className={"text-xl font-bold"}>
                        You already have an existing plan. Are you sure you want to upgrade?
                    </div>
                    <div className={"flex items-center justify-center mt-12"}>
                        <div onClick={()=>{goToStripe(paymentId)}} className={"px-10 py-2 bg-battGreen rounded-full cursor-pointer"}>
                            Yes
                        </div>
                        <div onClick={closePopUp} className={"px-10 py-2 rounded-full ml-6 border cursor-pointer"}>
                            No
                        </div>
                    </div>
                </div>
            </div>)
    }

    const goToStripe = async (paymentId:string)=>{
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY || "");
        http.post('/users/create-checkout-session', {
            email: localStorage.getItem("email"),
            payment_id: paymentId
        }).then((res) => {
            // @ts-ignore
            stripe.redirectToCheckout({sessionId: res.data})
        }).catch((e) => {
            console.log(e)
            alert(e?.response?.data);
        })
    }

    const makePayment = async (paymentId: string, type: string, planId: number) => {
        // dispatch(changeLoader(true));
        setPaymentId(paymentId);
        if (!localStorage.getItem("accessToken")) {
            navigate("/login")
        } else if (type === "prepaid") {
            const existingPlan = localStorage.getItem("planId");
            const existingPlanExpiry = localStorage.getItem("planExpiry");
            if (existingPlan && Number(existingPlan) === planId) {
                if (existingPlanExpiry) {
                    const existingPlanDate = new Date(existingPlanExpiry);
                    if (existingPlanDate > new Date()) {
                        alert("You already have an existing plan. Please contact admin for support.");
                        return;
                    }
                }
            }
            else if (existingPlan && Number(existingPlan) !== planId) {
                openPopUp();
                return;
            }
            await goToStripe(paymentId);
        } else {
            const subject = "Request for Pricing and Meeting to Discuss Custom Enterprise Tier";
            window.open(`mailto:info@battgenie.life?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(enterpriseEmailBody)}`);
        }
    }

    const fetchPlans = () => {
        dispatch(changeLoader(true));
        http.get("/plans").then((res) => {
            dispatch(changeLoader(false));
            setPricingData(res.data)
        }).catch((e) => {
            dispatch(changeLoader(false));
            console.log(e);
        })
    }

    useEffect(() => {
        fetchPlans();
    }, [])


    return (
        loading.value ? <Loader/> : <section
            className="relative z-10  bg-battLightBlue overflow-hidden pb-10 pt-20 md:px-20 dark:bg-dark lg:pb-[90px] lg:pt-[40px]">
            {showPopUp ? <Popup/> : null}
            <div className="container mx-auto md:px-0 px-1">
                <div className="md:-mx-4 flex flex-wrap">
                    <div className="w-full px-4">
                        <div className="mx-auto mb-[40px] max-w-[510px] text-center">
                            <h2 className="mb-3 text-3xl font-bold leading-[1.208] text-blue-dark dark:text-white sm:text-4xl md:text-[40px]">
                                Our Pricing Plans
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="md:-mx-4 flex flex-wrap justify-center ">
                    <div className="md:-mx-4 flex flex-wrap w-full">
                        {pricingData.map((item: any) => {
                            let cta_button_text = "";
                            if(validPlan){
                                if(Number(existingPlanId) == item.id){
                                    cta_button_text = "Current Plan"
                                }else{
                                    cta_button_text = "Upgrade"
                                }
                            }else{
                                cta_button_text = item.cta_button_text;
                            }
                            return (<PricingCard key={item.id}
                                                 makePayment={() => {
                                                     makePayment(item.stripe_payment_id, item.plan_type, item.id)
                                                 }}
                                                 active={false}
                                                 type={item.name}
                                                 price={item.amount}
                                                 subscription={item.duration}
                                                 description={item.description}
                                                 buttonText={cta_button_text}
                            >
                                {item.PlansItems.map((planitem: any) => {
                                    return planitem.isAvailableInPlan ? <List><SVG/>{planitem.feature}</List> :
                                        <List><CrossSVG/>{planitem.feature}</List>
                                })}
                            </PricingCard>)
                        })}
                    </div>
                </div>
            </div>
            <div className={"flex flex-col justify-center items-center px-4"}>
                <div
                    className={"text-battDarkBlue text-3xl font-bold w-4/5 mt-20 text-center border-b-2 border-b-battDarkBlue pb-8"}>
                    Frequently Asked Questions
                </div>
                <div className={"md:w-4/5"}>
                    {faqData.map((item) => {
                        return (
                            <div key={item.id} className={"border-b-2 border-b-battDarkBlue py-6"}>
                                <div className={"cursor-pointer flex justify-between"} onClick={() => {
                                    selectFAQ(item.id)
                                }}>
                                    <div>
                                        {item.question}
                                    </div>
                                    <div>
                                        <IoIosArrowUp size={30}
                                                      className={item.selected ? "faq-arrow-visible" : "faq-arrow-hidden"}/>
                                        <IoIosArrowDown size={30}
                                                        className={item.selected ? "faq-arrow-hidden" : "faq-arrow-visible"}/>
                                    </div>
                                </div>
                                <div className={item.selected ? "faq-answer-visible" : "faq-answer-hidden"}>
                                    {item.answer}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    );
};

export default Pricing;

// @ts-ignore
const PricingCard = ({children, description, price, type, subscription, buttonText, active, makePayment}) => {
    return (
        <>
            <div className="w-full px-4 md:w-1/2 lg:w-1/3 mb-4 md:mb-0">
                <div
                    className="relative z-10 py-4 md:py-0 overflow-hidden rounded-[10px] border-2 border-stroke bg-white px-4 shadow-pricing dark:border-dark-3 dark:bg-dark-2 sm:p-12 lg:px-6 xl:p-[15px]">
          <span className="block text-md font-semibold text-blue-dark">
            {type}
          </span>
                    <h2 className="mb-2 text-[30px] font-bold text-battGreen dark:text-white">
                        {price}
                        <span className="text-base font-medium text-body-color dark:text-dark-6">
              {subscription}
            </span>
                    </h2>
                    <p className="mb-4 border-b border-stroke text-base text-body-color dark:border-dark-3 dark:text-dark-6">

                    </p>
                    <div className="mb-5 flex flex-col gap-[14px]">{children}</div>
                    <a

                        onClick={makePayment}
                        className={` ${
                            active
                                ? "block w-full rounded-md bg-battGreen p-3 text-center text-base font-medium text-white transition hover:bg-opacity-90"
                                : "block w-full rounded-md border border-stroke bg-transparent p-3 text-center text-base font-medium text-primary transition hover:border-battGreen hover:bg-battGreen hover:text-white dark:border-dark-3 cursor-pointer"
                        } `}
                    >
                        {buttonText}
                    </a>
                    <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                  width={77}
                  height={172}
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx={86} cy={86} r={86} fill="url(#paint0_linear)"/>
                <defs>
                  <linearGradient
                      id="paint0_linear"
                      x1={86}
                      y1={0}
                      x2={86}
                      y2={172}
                      gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09"/>
                    <stop offset={1} stopColor="#C4C4C4" stopOpacity={0}/>
                  </linearGradient>
                </defs>
              </svg>
            </span>
                        <span className="absolute right-4 top-4 z-[-1]">
              <svg
                  width={41}
                  height={89}
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                />
                <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                />
                <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                />
                <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                />
                <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                />
                <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                />
                <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                />
                <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                />
                <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                />
                <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                />
                <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                />
                <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                />
                <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                />
                <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                />
                <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                />
                <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                />
                <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                />
                <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                />
                <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                />
                <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                />
                <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                />
                <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                />
                <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                />
                <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                />
              </svg>
            </span>
                    </div>
                </div>
            </div>
        </>
    );
};

// @ts-ignore
const List = ({children}) => {
    return (
        <p className="text-base text-body-color dark:text-dark-6 text-sm flex items-center">{children}</p>
    );
};
