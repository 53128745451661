import axios, { AxiosError } from 'axios';
// import API_CONSTANTS from './apiConstants';
// import CONSTANTS from './constants';

const baseUrl = process.env.REACT_APP_BASE_URL || "";
export const http = axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json',
    }
});

const requestHandler = (request: any) => {
    request.headers = {
        ...request.headers,
        Authorization: `Basic ${process.env.REACT_APP_AUTH_TOKEN}`,
        BattKeyToken: localStorage.getItem("accessToken"),
        'Content-Type': 'application/json'
    };
    return request;
};

const responseHandler = (response: any) => {
    return response;
};
//
const errorHandler = (error: any) => {
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
        window.location.href = process.env.REACT_APP_HOME_URL+"/login"??"";
    }
    return Promise.reject(error);
};
//
http.interceptors.request.use(
    (request) => requestHandler(request),
    (error: AxiosError) => errorHandler(error),
);

http.interceptors.response.use(
    (response) => responseHandler(response),
    (error: AxiosError) => errorHandler(error),
);
